import React from "react";
import { Typography, Layout, Image } from "antd";
import three_mvs from "../assets/three_mv.png"
import md_mvs_overview_zh from "../content/mvs-overview.md";
import md_mvs_overview_eng from "../content/mvs-overview-eng.md";
import md_mvs_process_zh from "../content/mvs-process.md";
import md_mvs_process_eng from "../content/mvs-process-eng.md";
import useRenderMarkdown from "./hooks/render-md";
import dataset_stat from "../assets/distribution.png";
const { Title, Paragraph } = Typography;
const { Content } = Layout;

export default function MVs(props) {
  const md_mvs_overview = props.lang === "zh" ? md_mvs_overview_zh : md_mvs_overview_eng;
  const md_mvs_process = props.lang === "zh" ? md_mvs_process_zh : md_mvs_process_eng;
  const isEn= props.lang === "en"
  return (
    <div>
      <Content style={{ padding: "10px 10px 10px 10px" }}>
        <Title level={2}>
          {props.lang === "zh" ? "多图表可视化" : "Multiple-View Visualization"}
        </Title>

        <section id="mvs-overview">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Paragraph style={{ fontSize: "16px" }}>
              {useRenderMarkdown(md_mvs_overview)}
            </Paragraph>
          </div>
          <Image
            src={three_mvs}
            style={{
              width: "100%",
              display: "flex",

              height: "auto",
            }}
            preview={false}
          />
          <div style={{ textAlign: "center" }}>
            {isEn
              ? "Examples of multiple chart visualizations on three platforms: (1) Calliope; (2) Tableau; (3) Plotly."
              : "三个平台上的多图表可视化示例：(1) Calliope; (2) Tableau; (3) Plotly"}
          </div>
        </section>
        <section id="mvs-process">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Paragraph style={{ fontSize: "16px" }}>
              {useRenderMarkdown(md_mvs_process)}
            </Paragraph>
          </div>
          <Image
            src={dataset_stat}
            style={{
              width: "100%",
              height: "auto",
              display: "block",
              margin: "auto",
            }}
            preview={false}
          />
          <div style={{ textAlign: "center" }}>
            {isEn ? "Distribution of datasets" : "数据集分布"}
          </div>
        </section>
      </Content>
    </div>
  );
}

import React from "react";
import { Layout, Anchor, ConfigProvider, Row, Col } from "antd";
import { Typography, Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import Introduction from "./modules/introduction.js";
import MVs from "./modules/mvs.js";
import Model from "./modules/model.js";
import Material from "./modules/material.js";

import idvxLogo from "./assets/idvx.png";
import antvLogo from "./assets/antv.png";

const { Title, Text } = Typography;
const { Header, Footer, Content, Sider } = Layout;

const defaultData = {
  borderRadius: "8px",
  colorPrimary: "#1AABF5",
};

const globalStyle = {
  width: "75%",
  margin: "0 auto",
  textAlign: "center",
};

const defaultContentStyle = {
  width: "45%",
  margin: "0 auto",
  marginLeft: "33.3%",
  minHeight: 280,
  background: "#ffffff",
  padding: "10px 10px 10px 10px",
  textAlign: "left",
};

const defaultSiderStyle = {
  width: "30%",
  position: "fixed",
  margin: "0 auto",
  height: "auto",
  padding: "10px 10px 10px 10px",
  overflow: "auto",
  top: 0,
  bottom: 0,
  textAlign: "left",
  background: "#ffffff",
};

const defaultHeaderFooterStyle = {
  margin: "0 auto",
  height: "auto",
  background: "#ffffff",
  lineHeight: "1.5",
  padding: "10px 10px 10px 10px",
};

const defaultSiderContentStyle = {
  margin: "0 auto",
  minHeight: "400px",
  background: "#ffffff",
  lineHeight: "1.5",
  padding: "10px 10px 10px 10px",
};

class GlobalLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      language: "en",
    };
  }

  render() {
    const ANCHOR_ITEMS = [
      {
        key: "1",
        href: "#introduction",
        title: this.state.language === "en" ? "Introduction" : "介绍",
      },
      {
        key: "2",
        href: "#mvs",
        title:
          this.state.language === "en"
            ? "Multiple-View visualizations"
            : "多图表可视化",
        children: [
          {
            key: "2-1",
            href: "#mvs-overview",
            title: this.state.language === "en" ? "Overview" : "概述",
          },
          {
            key: "2-2",
            href: "#mvs-process",
            title:
              this.state.language === "en"
                ? "Process & Guidelines"
                : "处理流程与标准",
          },
        ],
      },
      {
        key: "3",
        href: "#model",
        title: this.state.language === "en" ? "Model" : "模型",
        children: [
          {
            key: "3-1",
            href: "#model-overview",
            title: this.state.language === "en" ? "Overview" : "概述",
          },
          {
            key: "3-2",
            href: "#model-sourcecode",
            title: this.state.language === "en" ? "Source code" : "源代码",
          },
        ],
      },
      {
        key: "4",
        href: "#material",
        title: this.state.language === "en" ? "Paper Materials" : "论文材料",
      },
    ];

    const items = [
      {
        key: "1",
        label: "English",
      },
      {
        key: "2",
        label: "中文",
      }
    ];

    return (
      <ConfigProvider theme={{ token: { ...defaultData } }}>
        <Layout style={globalStyle}>
          <Sider style={defaultSiderStyle} width="25%">
            <Header style={defaultHeaderFooterStyle}>
              <Title level={3}>
                <b>
                  Chart2Vec: A Universal Embedding of Context-Aware
                  Visualizations
                </b>
              </Title>
            </Header>
            <Content style={defaultSiderContentStyle}>
              <Row>
                <div style={{ paddingBottom: "15px" }}>
                  <Dropdown
                    menu={{
                      items,
                      selectable: true,
                      defaultSelectedKeys: ["1"],
                      onClick: ({ key }) => {
                        if (key === "1") {
                          this.setState({ language: "en" });
                        } else {
                          this.setState({ language: "zh" });
                        }
                      },
                    }}
                  >
                    <Typography.Link>
                      <Space>
                        {this.state.language === "en" ? "English" : "中文"}
                        <DownOutlined />
                      </Space>
                    </Typography.Link>
                  </Dropdown>
                </div>
              </Row>
              <Row>
                <Anchor affix={false} items={ANCHOR_ITEMS} />
              </Row>
              <br />

              <Row>
                <Text>
                  <strong>Chart2Vec</strong> was invented by the{" "}
                  <a
                    class="reference external"
                    href="https://idvxlab.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    iDV<sup>x</sup> Lab
                  </a>{" "}
                  together with{" "}
                  <a
                    class="reference external"
                    href="https://antv.vision/en"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    AntV
                  </a>
                  .
                </Text>
              </Row>
              <br />
              <Row>
                <Col span={12}>
                  <a
                    href="https://idvxlab.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={idvxLogo}
                      alt="idvx lab logo"
                      style={{ width: "60%" }}
                    />
                  </a>
                </Col>
                <Col span={12}>
                  <a
                    href="https://antv.vision/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src={antvLogo}
                      alt="antv logo"
                      style={{ width: "80%" }}
                    />
                  </a>
                </Col>
              </Row>
            </Content>

            <Footer style={defaultHeaderFooterStyle}>
              <small>
                Copyright © 2023{" "}
                <a
                  class="reference external"
                  href="https://idvxlab.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Intelligent Big Data Visualization Lab (iDV<sup>x</sup> Lab)
                </a>{" "}
                All rights reserved. Made with{" "}
                <a
                  class="reference external"
                  href="https://ant.design/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Ant Design
                </a>
                .
              </small>
            </Footer>
          </Sider>

          <Content style={defaultContentStyle}>
            <section id="introduction">
              <Introduction lang={this.state.language} />
            </section>
            <section id="mvs">
              <MVs lang={this.state.language} />
            </section>
            <section id="model">
              <Model lang={this.state.language} />
            </section>
            <section id="material">
              <Material lang={this.state.language} />
            </section>
          </Content>
        </Layout>
      </ConfigProvider>
    );
  }
}

export default GlobalLayout;

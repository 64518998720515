import React from "react";
import { Typography, Layout, Button,Image } from "antd";
import { GithubOutlined } from "@ant-design/icons";
import md_model_overview_eng from "../content/model-overview-eng.md";
import md_model_overview_zh from "../content/model-overview.md";
import emb_encoder_pic from "../assets/emb_encoder.png"
import useRenderMarkdown from "./hooks/render-md";
const { Title, Paragraph } = Typography;
const { Content } = Layout;

export default function Model(props) {
  const md_model_overview = props.lang === "en" ? md_model_overview_eng : md_model_overview_zh;
  const isEn= props.lang === "en"
  return (
    <div>
      <Content style={{ padding: "10px 10px 10px 10px" }}>
        <Title level={2}>
          {props.lang === "en" ? "Model Overview" : "模型"}
        </Title>

        <section id="model-overview">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Paragraph style={{ fontSize: "16px" }}>
              {useRenderMarkdown(md_model_overview)}
            </Paragraph>
          </div>
          <Image
            src={emb_encoder_pic}
            style={{
              width: "100%",
              height: "auto",
              display: "block",
              margin: "auto",
            }}
            preview={false}
          />
          <div style={{ textAlign: "center" }}>{isEn ? "Architecture of Chart2Vec" : "Chart2Vec 模型架构"}</div>
        </section>
        <section id="model-sourcecode">
          <Title level={3}>
            {props.lang === "en" ? "Source Code" : "源代码"}
          </Title>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Paragraph style={{ fontSize: "16px" }}>
              {props.lang === "en" ? "Source code of Chart2Vec is available in our GitHub repository.": "Chart2Vec 的源代码已在 GitHub 仓库中开源。"}
            </Paragraph>
          </div>
          <Button
            type="primary"
            href="https://github.com/idvxlab/chart2vec"
            target="_blank"
            rel="noopener noreferrer"
          >
            <GithubOutlined />
            Source Code
          </Button>
        </section>
      </Content>
    </div>
  );
}

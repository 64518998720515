import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown";
import remarkMath from "remark-math";
import rehypeKatex from "rehype-katex";
import "katex/dist/katex.min.css";
import remarkGfm from "remark-gfm";

/**
 * This hook is used to render md for react projects.
 * It now supports tables and inline links, but not all HTML grammar.
 * @param source_markdown : route to the markdown file
 * @returns JSX object that renders this markdown file
 */

export default function useRenderMarkdown(source_markdown) {
  let [data, setData] = useState(null);
  useEffect(() => {
    fetch(source_markdown)
      .then((text) => text.text())
      .then((text) => {
        setData(text);
      });
  }, [source_markdown]);

  return (
    <ReactMarkdown
      children={data}
      remarkPlugins={[remarkMath, remarkGfm]}
      rehypePlugins={[rehypeKatex]}
    />
  );
}

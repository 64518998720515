import React from "react";
import {
  CloudDownloadOutlined,
  LinkOutlined
} from "@ant-design/icons";
import Chart2Vec_video from "../assets/Chart2Vec-video.mp4"
import { Layout, Card, Typography, Row, Col } from "antd";
const { Title, Text } = Typography;
const { Content } = Layout;
export default class Materials extends React.Component {
  getContent(icon, content) {
    return (
      <div>
        <Text style={{ fontWeight: "bold", fontSize: "16px" }}>
          {icon} &nbsp;
          {content}
        </Text>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Content style={{ padding: "10px 10px 10px 10px" }}>
          <Title level={2}>
            {this.props.lang === "en" ? "Paper Materials" : "论文材料"}
          </Title>
          <video width="100%"  controls>
            <source src={Chart2Vec_video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <Row gutter={[16, 16]} style={{padding: "10px 10px 10px 10px"}}>
            <Col span={12}>
              <Card
                hoverable="True"
                onClick={() => {
                  window.open("https://arxiv.org/pdf/2306.08304.pdf");
                }}
              >
                {this.getContent(<LinkOutlined />, "Paper(PDF)")}
              </Card>
            </Col>
            <Col span={12}>
              <Card
                hoverable="True"
                onClick={() => {
                  window.open(
                    "https://drive.google.com/drive/folders/1RABMIr1Dl_gD20IJUSmee8MZ741XbZAD?usp=sharing"
                  );
                }}
              >
                {this.getContent(<CloudDownloadOutlined />, "Chart Facts")}
              </Card>
            </Col>

            <Col span={12}>
              <Card
                hoverable="True"
                onClick={() => {
                  window.open(
                    "https://drive.google.com/drive/folders/1CmfkdbKWZvcw51YM4jJeeDrePLM7ffcd?usp=sharing"
                  );
                }}
              >
                {this.getContent(
                  <CloudDownloadOutlined />,
                  "Tableau Images"
                )}
              </Card>
            </Col>
            <Col span={12}>
              <Card
                hoverable="True"
                onClick={() => {
                  window.open("https://datacalliope.com/");
                }}
              >
                {this.getContent(<LinkOutlined />, "Calliope")}
              </Card>
            </Col>
            <Col span={12}>
              <Card
                hoverable="True"
                onClick={() => {
                  window.open("https://ieeexplore.ieee.org/abstract/document/10485458");
                }}
              >
                {this.getContent(<LinkOutlined />, "TVCG Link")}
              </Card>
            </Col>
          </Row>
        </Content>
      </div>
    );
  }
}
